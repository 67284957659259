import React, {
  useState,
  useMemo,
  useContext,
  useEffect,
} from 'react';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import AuthenticationContext from '../context/AuthenticationContext.jsx';
import PlantSelectorContext from '../context/PlantSelectorContext.jsx';
import apiConfig from '../../config.js';

const accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const PlantSelectorProvider = ({ children }) => {
  const [project, setProject] = useState({});
  const [projectPlantLists, setProjectPlantLists] = useState([]);
  const [projectInventoryLists, setProjectInventoryLists] = useState([]);
  const [projectPlantingPlans, setProjectPlantingPlans] = useState([]);
  const [packedPlantingPlans, setPackedPlantingPlans] = useState([]);
  const [project3DMaps, setProject3DMaps] = useState([]);
  const [currentLocation, setCurrentLocation] = useState([-122.2712, 37.8044]);

  const { api } = useContext(AuthenticationContext);

  const { id } = useParams();

  const navigate = useNavigate();

  const getLatLngLoc = async (city, state) => {
    try {
      const results = await api.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${city}, ${state}.json?access_token=${accessToken}`);
      if (results?.data?.features?.length) {
        const { center } = results.data.features[0];
        setCurrentLocation(center);
      }
    } catch (err) {
      // do nothing, doesnt matter if location cant update
    }
  };

  const getPackedPlantingPlans = async () => {
    try {
      const res = await api.get(`${apiConfig.project}/${project._id}/packed-planting-plan`);
      const { data } = res;
      if (!data) {
        throw new Error('Unable to retrieve packed planting plans');
      }
      setPackedPlantingPlans(data);
    } catch (err) {
      toast.error('Unable to retrieve packed planting plans');
    }
  };

  const getPlantingPlans = async () => {
    try {
      const res = await api.get(`${apiConfig.project}/${project._id}/planting-plans`);
      const { data } = res;
      setProjectPlantingPlans(data);
    } catch (err) {
      toast.error('Unable to retrieve planting plans');
    }
  };

  const getProjectPlantLists = async () => {
    try {
      const res = await api.get(`${apiConfig.project}/${project._id}/plant-lists`);
      const { data } = res;
      setProjectPlantLists(data);
    } catch (err) {
      toast.error('Unable to retrieve plant lists for this project. Please contact Hyphae for assistance.');
    }
  };

  const getProjectInventoryLists = async () => {
    try {
      const res = await api.get(`${apiConfig.project}/${project._id}/inventory-lists`);
      const { data } = res;
      if (!data) {
        throw new Error('Inventory list information incorrect');
      }
      setProjectInventoryLists(data);
    } catch (err) {
      toast.error('Unable to retrieve inventory lists for this project. Please contact Hyphae for assistance.');
    }
  };

  const get3DMaps = async () => {
    try {
      const res = await api.get(`${apiConfig.project}/${project._id}/3d-maps`);
      const maps = res.data;
      setProject3DMaps(maps);
    } catch (err) {
      // pass
    }
  };

  const getProjectDoc = async (projectId) => {
    try {
      const res = await api.get(`${apiConfig.project}/${projectId}`);
      const { data } = res;
      if (!data) {
        throw new Error('Could not retrieve project');
      }
      setProject(data);
    } catch (err) {
      navigate('/not-found');
    }
  };

  // get project info
  useEffect(() => {
    if (id) {
      if (id === 'not-found') {
        return;
      }
      getProjectDoc(id);
    }
  // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (project?._id) {
      getProjectInventoryLists();
      getProjectPlantLists();
      getPlantingPlans();
      getPackedPlantingPlans();
      get3DMaps();
      getLatLngLoc(project.city, project.state);
    }
  // eslint-disable-next-line
  }, [project]);

  const value = useMemo(() => ({
    project,
    setProject,
    projectPlantLists,
    projectPlantingPlans,
    packedPlantingPlans,
    projectInventoryLists,
    currentLocation,
    project3DMaps,
    getProjectDoc,
    getPlantingPlans,
    getProjectPlantLists,
    getPackedPlantingPlans,
    getProjectInventoryLists,
  // eslint-disable-next-line
  }), [
    project,
    project3DMaps,
    currentLocation,
    projectPlantLists,
    packedPlantingPlans,
    projectPlantingPlans,
    projectInventoryLists,
  ]);

  return (
    <PlantSelectorContext.Provider value={value}>
      {children}
    </PlantSelectorContext.Provider>
  );
};

export default PlantSelectorProvider;
