const webBasePath = process.env.WEB_BASE_PATH ? process.env.WEB_BASE_PATH : '';
const apiRoutes = {
  main: '/api',
  auth: '/api/auth',
  query: 'api/query',
  project: '/api/project',
  plantList: '/api/project/plantList',
  uploadData: '/api/uploadData',
  inventory: '/api/inventory',
  nursery: '/api/nursery',
  photo: '/api/photo',
  location: '/api/location',
  map: '/api/map',
  plantingPlan: '/api/plantingPlan',
  geometryFeatures: '/api/geometryFeatures',
};

module.exports = apiRoutes;

