import React, {
  useState,
  useMemo,
  useContext,
  useEffect,
} from 'react';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import AuthenticationContext from '../context/AuthenticationContext.jsx';
import PlantSelectorContext from '../context/PlantSelectorContext.jsx';
import apiConfig from '../../config.js';

const accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const PlantSelectorProvider = ({ children }) => {
  const [project, setProject] = useState({});
  const [projectPlantLists, setProjectPlantLists] = useState([]);
  const [projectInventoryLists, setProjectInventoryLists] = useState([]);
  const [projectPlantingPlans, setProjectPlantingPlans] = useState([]);
  const [packedPlantingPlans, setPackedPlantingPlans] = useState([]);
  const [project2DLayers, setProject2DLayers] = useState([]);
  const [project3DLayers, setProject3DLayers] = useState([]);
  const [maps, setMaps] = useState([]);
  const [projectSimulations, setProjectSimulations] = useState([]);
  const [currentLocation, setCurrentLocation] = useState([-122.2712, 37.8044]);

  const { api, user } = useContext(AuthenticationContext);

  const { id } = useParams();

  const navigate = useNavigate();

  const getLatLngLoc = async (city, state) => {
    try {
      const results = await api.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${city}, ${state}.json?access_token=${accessToken}`);
      if (results?.data?.features?.length) {
        const { center } = results.data.features[0];
        setCurrentLocation(center);
      }
    } catch (err) {
      // do nothing, doesnt matter if location cant update
    }
  };

  const getPackedPlantingPlans = async () => {
    try {
      const res = await api.get(`${apiConfig.project}/${project._id}/packed-planting-plan`);
      const { data } = res;
      if (!data) {
        // throw new Error('Unable to retrieve packed planting plans');
      }
      setPackedPlantingPlans(data);
    } catch (err) {
      toast.error('Unable to retrieve packed planting plans');
    }
  };

  const getPlantingPlans = async () => {
    try {
      const res = await api.get(`${apiConfig.project}/${project._id}/planting-plans`);
      const { data } = res;
      setProjectPlantingPlans(data);
    } catch (err) {
      toast.error('Unable to retrieve planting plans');
    }
  };

  const getProjectPlantLists = async () => {
    try {
      const res = await api.get(`${apiConfig.project}/${project._id}/plant-lists`);
      const { data } = res;
      setProjectPlantLists(data);
    } catch (err) {
      toast.error('Unable to retrieve plant lists for this project. Please contact Hyphae for assistance.');
    }
  };

  const getProjectInventoryLists = async () => {
    try {
      const res = await api.get(`${apiConfig.project}/${project._id}/inventory-lists`);
      const { data } = res;
      if (!data) {
        throw new Error('Inventory list information incorrect');
      }
      setProjectInventoryLists(data);
    } catch (err) {
      toast.error('Unable to retrieve inventory lists for this project. Please contact Hyphae for assistance.');
    }
  };

  const get2DMaps = async () => {
    try {
      const res = await api.get(`${apiConfig.project}/${project._id}/2d-maps`, { params: { returnLoneLayersOnly: true, user: user?.email } });
      const { data } = res;
      if (!data) {
        throw new Error('Unable to retrieve 2d layer information.');
      }
      setProject2DLayers(data);
    } catch (err) {
      // pass
    }
  };

  const get3DMaps = async () => {
    try {
      const res = await api.get(`${apiConfig.project}/${project._id}/3d-maps`, { params: { returnLoneLayersOnly: true } });
      const { data } = res;
      if (!data) {
        throw new Error('Unable to retrieve 3d layer information.');
      }
      setProject3DLayers(data);
    } catch (err) {
      // pass
    }
  };

  const getMaps = async () => {
    try {
      const res = await api.get(`${apiConfig.project}/${project._id}/maps`);
      const { data } = res;
      if (!data) {
        throw new Error('Unable to retrieve project map information.');
      }
      setMaps(data);
    } catch (err) {
      // pass
    }
  };

  const getSimulations = async () => {
    try {
      const res = await api.get(`${apiConfig.project}/${project._id}/simulations`);
      const { data } = res;
      if (!data) {
        throw new Error('Unable to retrieve project simulations.');
      }
      setProjectSimulations(data);
    } catch (err) {
      // pass
    }
  };

  const getProjectDoc = async (projectId) => {
    try {
      const res = await api.get(`${apiConfig.project}/${projectId}`);
      const { data } = res;
      if (!data) {
        throw new Error('Could not retrieve project');
      }
      setProject(data);
    } catch (err) {
      navigate('/not-found');
    }
  };

  const refreshMappingAssets = () => {
    getPlantingPlans();
    getPackedPlantingPlans();
    get2DMaps();
    get3DMaps();
    getMaps();
  };

  // get project info
  useEffect(() => {
    if (id) {
      if (id === 'not-found') {
        return;
      }
      getProjectDoc(id);
    }
  // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (project?._id) {
      getProjectInventoryLists();
      getProjectPlantLists();
      refreshMappingAssets();
      getSimulations();
      getLatLngLoc(project.city, project.state);
    }
  // eslint-disable-next-line
  }, [project]);

  const value = useMemo(() => ({
    project,
    setProject,
    projectPlantLists,
    projectPlantingPlans,
    packedPlantingPlans,
    projectInventoryLists,
    currentLocation,
    project3DLayers,
    project2DLayers,
    maps,
    projectSimulations,
    getProjectDoc,
    getPlantingPlans,
    getProjectPlantLists,
    getPackedPlantingPlans,
    getProjectInventoryLists,
    refreshMappingAssets,
    setProjectSimulations,
    getSimulations,
  // eslint-disable-next-line
  }), [
    project,
    project2DLayers,
    project3DLayers,
    currentLocation,
    projectPlantLists,
    packedPlantingPlans,
    projectPlantingPlans,
    projectInventoryLists,
    maps,
    projectSimulations,
  ]);

  return (
    <PlantSelectorContext.Provider value={value}>
      {children}
    </PlantSelectorContext.Provider>
  );
};

export default PlantSelectorProvider;
